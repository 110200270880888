<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div class="grid30">
      <div>
        <h2>Образование и квалификация</h2>

        <h4>образование</h4>
        <div style="white-space: pre-line">
          {{ employee.education | df }}
        </div>

        <h4>специальность</h4>
        <p style="white-space: pre-line">{{ employee.specialty | df }}</p>

        <h4>ученая степень</h4>
        <p style="white-space: pre-line">{{ employee.degree | df }}</p>

        <h4>область квалификации</h4>
        <p style="white-space: pre-line">{{ employee.qualification | df }}</p>


      </div>

      <div style="background: #E3EBFC;">
        <img :src="'https://supt.nisse.ru/publicfiles/' + employee.imagePublicFileName"
             style="display:block;max-width:200px;max-height:200px;" v-if="employee.imagePublicFileName"/>
        <a href="#" @click.prevent="$refs.imginput.$refs.input.click()"
           v-if="!employee.imagePublicFileName">Загрузить изображение</a>
        <a href="#" @click.prevent="deleteImage()" v-else style="font-size:75%">Удалить изображение</a>
        <v-file-input style="display:none" ref="imginput" @change="uploadImage($event)" accept="image/*">
        </v-file-input>

        <h2>Контакты</h2>


        <h4>электронная почта</h4>
        <p><a :href="'mailto:' + employee.email" v-if="employee.email">{{ employee.email }}</a><span
            v-else>—</span></p>


        <h4>телефон</h4>
        <p>{{ employee.phoneNumber | df }}</p>

      </div>

      <div>
        <h2>Текущее место работы</h2>
        <div v-if="employee.jobs == null || employee.jobs.length === 0">—</div>
        <div v-for="(job, jobIndex) in employee.jobs" :key="'jobi' + jobIndex">
          <h3>
            <router-link v-if="job.organization != null" :to="'/counterparty/' + job.organization.id">
              {{
                job.organization.shortName != null && job.organization.shortName.length > 0 ?
                    job.organization.shortName : job.organization.fullName
              }}
            </router-link>
          </h3>

          <h4>должность по договору</h4>
          <p>{{ job.position | df }}</p>

          <h4>дата трудоустройства</h4>
          <p>{{ job.employmentDate | formatDate }}</p>

          <h4>Номер и дата договора</h4>
          <p>{{ job.contractNumAndDate | df }}</p>

          <h4>тип занятости</h4>
          <p>{{ job.jobType ? jobTypeDict[job.jobType] : '—' }}</p>
        </div>
      </div>
      <div>
        <h2>Дополнительная информация</h2>
        <div style="white-space: pre-line">{{ employee.extraInfo | df }}
        </div>

      </div>
      <div>
        <h2>Недостающие документы</h2>
        <div style="white-space: pre-line">{{ employee.missingDocs | df }}</div>

      </div>
    </div>
  </transition>
</template>

<script>
import {dictToObject} from "@/modules/CommonUtils";
import {jobTypeDict} from "@/modules/NSI";
import api from "@/modules/api";

export default {
  name: "EmployeeInfoTab",
  props: ['employee'],
  data() {
    return {
      jobTypeDict: dictToObject(jobTypeDict)
    }
  },
  methods: {
    async uploadImage(e) {
      let formData = new FormData()
      formData.append('file', e)
      console.log('loading')
      let req = await api.postFormData('/supmain/experts/' + this.employee.id + '/image', formData)
      if (req.ok)
        window.location.reload()
      else
        alert('Ошибка загрузки файла')
    },

    async deleteImage() {
      if (window.confirm('Вы действительно хотите удалить изображение?')) {
        let req = await api.delete('/supmain/experts/' + this.employee.id + '/image')
        if (req.ok)
          window.location.reload()
        else
          alert('Ошибка загрузки файла')
      }
    }
  }
}
</script>

<style scoped>

</style>